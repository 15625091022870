@media only screen and (max-width:62.25em) { 
  .tsizes div div {margin-bottom: 5px;}

}

@media only screen and (max-width:50em) { 
	/* CSS rules here */

.outside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
}

.sign-in-form.wait button {
  padding: 10px;
  color: black;
  font-weight: 500;
}

html, body {
  background: #e2e2e2;
  font-family: 'farm_neue_lt_std55_roman';
  font-size: 11px;
}

@media (max-width: 1600px){
  html {
    font-size: 11px;
  }
}
@media (max-width: 1024px){
  html {
    font-size: 11px;
  }
}
@media (max-width: 500px){
  html {
    font-size: 10px;
  }
}

body {
  margin: 0;
  padding: 0;

}
* {
  box-sizing: border-box;
}
textarea {
  outline: none;
  resize: none;
}
input::placeholder, textarea::placeholder {
  color: #000;
}
p {
  margin: 0px;
}
button,input {
  cursor: pointer;
  outline: none;
}

.main {
  height: 100vh;
  overflow:auto;
  display: flex;
}
.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1170px;
  width: 100%;
  padding: 0 2rem;
  position: relative;
  margin: 0 auto;
}
.logo {
  height: 40px;

  top: 30px;
}

button {
  background: transparent;
  border: 0;
  font-size: 16px;
}

.full-width-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 600px;
  margin: auto;
}
.input-username {
  padding: 0;
  max-width: 100%;
  color: #000;
  font-size: 2rem;
  border:none;
  text-align: center;
  outline: none;
}
.button-small-text {
  background: none;
  border: none;
  color: #000;
  font-size: 1rem;
  outline: none;
}
.button-big-text, .size-profile_suggest-text,.save-btn {
  background: none;
  border: none;
  color: #000;
  font-size: 1.3rem;
  outline: none;
  margin-bottom: 1rem;
}

.size-profile {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 600px;
  margin-top: 17%;
  margin-bottom: 5%;
}
.header {
  color: #000;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.title {
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.subtitle {
  color: #000;
  font-size: 1rem;
  margin-bottom: .5rem;
}
.size-profile_row {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}
.size-profile_row input {
  border: none;
  font-size: 1.4rem;
  color: #000;
  padding: 0;
  max-width: 100%;
  text-align: center;
  background-color: transparent;
}
.size-profile_button-row {
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.size-profile_button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #8D8D8D;
  position: relative;
  padding: 0.2em .4em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.size-profile_button:hover {
  color: #000;
}
.size-profile_button.selected {
  color: #000;
}
.size-profile_button span {
  position: absolute;
  bottom: -1em;
  font-size: .65rem;
  color: #000;
}

.size-profile_suggest-input {
  max-width: 300px;
  width: 100%;
  max-height: 6rem;
  border: 1px solid #000;
  color: #000;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  text-align: center;
  background-color: transparent;
}
.size-profile_suggest-text:nth-of-type(2){
  margin-top: 1rem;
}
.fast-button {
  width: 100%;
  height: 200px;
  max-height: 290px;
  position: relative;
}
.save-btn {
  font-size: 1.7rem;
}
.fast-button iframe {
    display: initial !important;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    position: absolute !important;
    bottom: 0px !important;
    right: 0px !important;
    background: transparent !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

@media (max-width: 1600px){
  .fast-button {
    width: 450px;
    height: 157px;
  }
}
@media screen and (max-width: 800px){
  .log {
    height: 50px;
    top: 2%;
  }
  .size-profile {
    margin-top: calc(10% + 50px);
  } 
}

@media screen and (max-width: 500px){
.fast-button {
    width: 100%;
  }
}

.inverted p {
  color: white;
}
.inverted input,.inverted button,.inverted textarea {
  color: #fff;
}
.inverted textarea {
  border-color: #fff;
}

.inverted .size-profile_button {
  color:rgba(255,255,255,.7);
}
.inverted .size-profile_button:hover {
  color: #fff;
}
.inverted .size-profile_button.selected {
  color: #fff;
}
.inverted .size-profile_button span {
  color:rgba(255,255,255,.7);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.size-profile input {
  font-size: 32px;
  text-align: center;
  border: 0;
  border-bottom: 2px solid;
  padding: 10px;
  width: 50%;
  margin: auto;
}
.size-profile_button {
  background: none;
  border: none;
  font-size: 41px;
  color: #8d8d8d;
  position: relative;
  padding: 7px 0.4em;
}

.size-profile input {
  font-size: 32px;
  text-align: center;
  border: 0;
  border-bottom: 2px solid;
  padding: 10px;
  width: 50%;
  margin: auto;
}

.size-profile input {
  font-size: 32px;
  text-align: center;
  border: 0;
  border-bottom: 2px solid;
  padding: 10px;
  width: 50%;
  margin: auto;
}
section.question {
  background: white;
  padding: 16px;
  margin: 18px;
  border-radius: 50px;
  box-shadow: 10px 10px 10px #49494963;
}

.size-profile_button {
  background: none;
  border: none;
  font-size: 41px;
  color: #8d8d8d;
  position: relative;
  padding: 7px 0.4em;
 
}

.size-profile_button input{
  width: 100%;
}

.tsizes div div {margin-bottom: 9px;}


.inverted section.question {
  background: black;
}
.introText h1{
  font-weight: 700;
  text-align: left;
  color: #fff;
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-size: 44px;
  border: 0;
  text-align: center;
  margin-top: 0;
letter-spacing: 0;

}

form.grid-form {
  width: 90%;
  margin: auto;
  max-width: 1130px;
  padding: 10px 0;
}

.inverted form.grid-form {
  width: 90%;
  margin: auto; 
  padding: 10px 0;
  color:white;
}
 .insides {

  margin: auto;
  max-width: 1130px;
  border: 3px solid black;
  padding: 10px 0;
}

.inverted .insides {

  margin: auto;
;
  border: 3px solid white;
  padding: 10px 0;
}

input.input-username {
  text-align: left;
}

.inverted .grid-form fieldset legend {
  border: none;
  border-bottom: 3px solid #fff;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 5px;
  position: static;
  width: 100%;
  padding: 8px;
  text-transform: uppercase;
}

.inverted .grid-form [data-row-span] {
  border-bottom: 1px solid #fff;
  width: 100%;
  zoom: 1;
}

.inverted .grid-form [data-row-span] [data-field-span] label:first-child {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #fff;
  display: block;
  margin-bottom: 4px;
}

.inverted .header h1 {
  margin: 0;
  margin-top: 50px;
  color: white;
}
.inverted  .grid-form [data-row-span] [data-field-span] {
  border-right: 1px solid #fff;
  display: block;
}

.inverted .sign-in-form.wait {
  color: white;
}

.inverted .sign-in-form.wait button {
  padding: 10px;
  color: white;
  font-weight: 500;
}

.noBorder{
border: 0 !Important;
}

.introText {
  font-size: 23px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: .12px;
  -webkit-text-stroke-color: #000;
  padding-top: 100%;
  padding-bottom: 50%;
  margin-top: 100%;
  text-align: justify;
  letter-spacing: -1px;
  margin-bottom: 100%;
}
.full-width-form {
  position: fixed;
}

.full-width-form h2 {
  background: #E91E63;
  color: white;
  font-size: 26px;
}
button.deleteBtn {
  position: absolute;
  margin-left: -28px;
  background: #990000;
  color: white;
  font-size: 12px;
 margin-top: 12px;
}

.center.mylogo img {
  max-width: 210px;
  padding: 10px;
}

.inverted button.save-btn.google {
  background: white;
  color: black;
}

.sign-in-form.wait {
  text-align: center;
}
.header h1 {
  margin: 0;
  margin-top: 50px;
  color: black;
}


fieldset.itemsList input {
  padding: 10px !important;
  border: 0 !important;
  border-bottom: 2px solid black !important;
}


.inverted fieldset.itemsList input {
  padding: 10px !important;
  border: 0 !important;
  border-bottom: 2px solid white !important;
}


button.google {
  border: 2px solid;
  background: transparent;
  border-radius: 110px;
  padding: 0 8px;
  font-size: 11px;
}

.header h1 {
  margin: 0;
  margin-top: 50px;
  color: black;
}

button.google {
  border: 2px solid;
  background: transparent;
  border-radius: 110px;
  padding: 0 8px;
  font-size: 11px;
}

button.save-btn.google {
  padding: 5px 20px;
  font-size: 26px;
}

.center {
  text-align: center;
  padding-top: 20px;
}

button.save-btn.google:hover {
  background: black;
  color: white;
}

}